// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-about-yoga-js": () => import("./../../../src/templates/about-yoga.js" /* webpackChunkName: "component---src-templates-about-yoga-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-classes-page-js": () => import("./../../../src/templates/classes-page.js" /* webpackChunkName: "component---src-templates-classes-page-js" */),
  "component---src-templates-main-page-js": () => import("./../../../src/templates/main-page.js" /* webpackChunkName: "component---src-templates-main-page-js" */),
  "component---src-templates-teaching-page-js": () => import("./../../../src/templates/teaching-page.js" /* webpackChunkName: "component---src-templates-teaching-page-js" */)
}

